import * as Yup from "yup"


// validate fields and provide errors.
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  referrer_id: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Please provide a valid email address")
    .required("This field is required"),
})

export default validationSchema
