import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  List,
  ListItem,
  Span,
  ContactLine,
  CTA
} from "@/components/index"
import { color } from "@/theme/index"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { ReferFriend } from "../components/Forms/ReferFriend"

export default function Referral() {
  const location = useLocation()
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Referral Program"
        description="Give Your Friends $200 Off and Get a FREE Month of Mentorship"
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={ "9.6rem"}
        pb={["4.8rem", null, null, "0"]}
      >
        <Heading level="1" mt="5rem" mb="2rem">
          Give the Gift of Fretboard Fluency 🎁
        </Heading>
        <Width
          display="flex"
          flexDirection={["column", "row"]}
          pt={["0", null, null, "2.2rem"]}
          pb={["0", null, null, "13.9rem"]}
        >
          <Box
            width={["100%", 5 / 12]}
            textAlign="left"
            mb={["6rem", null, null, "0"]}
            px={["2.4rem", "0"]}
          >
            <Text>
              Give Your Friends{" "}
              <Span fontWeight="600" color="amethyst">
                $200 Off
              </Span>{" "}
            </Text>
            <Text mb="2.3rem">
              Get a{" "}
              <Span fontWeight="600" color="amethyst">
                FREE Month of Mentorship!
              </Span>{" "}
            </Text>
            <Text mb="2.3rem">
              Do you know someone who’s been playing the same songs for years
              and could really use a little scale practice to break out of the
              rut?
            </Text>
            <Text mb="2.3rem">
              {" "}
              Well we are opening our doors to your friends! Give the gift of
              fretboard fluency, because as the Universal Law goes, that which
              you give, you gain.
            </Text>
            <Text mb="2.3rem">Here’s how it works:</Text>
            <List bullets="checkmark">
              <ListItem>
                Invite your friend by emailing them an invite or sending them
                your invite link.
              </ListItem>
              <ListItem>
                If your friend is qualified, they will get a $200 discount upon
                enrolling.
              </ListItem>
              <ListItem>
                If your friend enrolls, you'll get a free month of mentorship.
              </ListItem>
            </List>
          </Box>
          <Box width={["100%", 6 / 12]} textAlign="left" ml={["0", "auto"]}>
            <Box
              width={["100%", "80%", 12 / 12, null]}
              p="2.4rem"
              textAlign="center"
              mb={"2.4rem"}
              bg={color.opal}
              borderRadius=".6rem"
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={"center"}
              >
                <Text fontWeight="bold" color="amethyst">
                  Your Referral Link
                </Text>
              </Box>
              <Text level={2} fontSize={["15px", null, null, "17px"]}>
                {"https://learnwithsonora.com/invite?rid=" +
                  urlParameters.query?.id}
              </Text>
              {urlParameters.query?.id &&
                  <CTA target="new" to={"https://www.facebook.com/sharer/sharer.php?u=https://www.learnwithsonora.com/invite?rid="+urlParameters.query?.id} variant="primary" mt="1.5rem">
                Share on Facebook
              </CTA>}
                  
            </Box>

            <ReferFriend headerOne="INVITE" headerTwo="Send an email" />
            <Box display={["flex", "none"]} mt="3.6rem" justifyContent="center">
              <ContactLine textAlign="center" />
            </Box>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}
