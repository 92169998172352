import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Formik, Form } from "formik"
import validationSchema from "./validationSchema"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { Box, Button, Text, Label, Input, Heading } from "@/components/index"
import { inviteHubspotContact } from "@/utils/hubspot"

export const ReferFriend = ({
  headerOne = "Invite", 
  headerTwo = "Send an Email", 
  cta = "Invite",
}) => {
  const [isError, setIsError] = useState(false)
  const [log, setLog] = useState(null)
  const location = useLocation()

  // see if there are any parameters in the url
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""

  const _firstname =
    typeof window !== "undefined" && urlParameters.query.firstname
      ? urlParameters.query.firstname
      : ""
  const _firstname_touched = _firstname ? true : false

  const _email =
    typeof window !== "undefined" && urlParameters.query.email
      ? urlParameters.query.email
      : ""
  const _referrer_id =
      typeof window !== "undefined" && urlParameters.query.id
        ? urlParameters.query.id
        : ""
  const _email_touched = _email ? true : false

  const referrer_id = urlParameters.query?.id
  console.log("referrer_id",referrer_id)
  let invited
    console.log("LOG", log)
  return (
    <Box
      boxShadow="0px 0px 28px rgba(27, 6, 70, 0.2)"
      borderRadius="0.4rem"
      px={["2.4rem", "3.6rem"]}
      py="3.6rem"
      bg="white"
    >
      <Box textAlign="center">
        <Text variant="subhead">{headerOne}</Text>
        <Heading level={2}>{headerTwo}</Heading>
      </Box>
      <Formik
        initialValues={{
          first_name: _firstname,
          email: _email,
          referrer_id: _referrer_id
        }}
        initialTouched={{
          first_name: _firstname_touched,
          email: _email_touched,
          referrer_id: true

        }}
        
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
            invited = values
            console.log("SFSDFGSd", values)
          if(values.email) {
            values.email = values.email.toLowerCase()
          }
          values.referrer_id = referrer_id
          try {
            inviteHubspotContact(values)
            setLog(values)

            actions.setSubmitting(false)
          } catch (error) {
            actions.setSubmitting(false)
            setIsError(true)
            setLog(error.response)
          }
        }}
      >
        {({ errors, touched, handleSubmit, isSubmitting }) => (
          <Form name="create-account">
            {/* first name */}
            <Label htmlFor="first_name">
              {touched.first_name && !errors.first_name && (
                <span>Friends First Name*</span>
              )}
              <Input
                type="name"
                name="first_name"
                id="first_name"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="given-name"
                placeholder={"Friend's First Name*"}
                isvalid={touched.first_name && !errors.first_name}
                error={touched.first_name && errors.first_name}
              />
              {touched.first_name && errors.first_name && (
                <>
                  <Text variant="small" color="alertRed" mb="1.8rem">
                    {errors.first_name}
                  </Text>
                </>
              )}
            </Label>

        

            {/* email */}
            <Label htmlFor="email">
              {touched.email && !errors.email && <span>Email*</span>}
              <Input
                type="email"
                name="email"
                id="email"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="email"
                placeholder={"Friend's Email address*"}
                isvalid={touched.email && !errors.email}
                error={touched.email && errors.email}
              />
              {touched.email && errors.email && (
                <>
                  <Text variant="small" color="alertRed" mb="1.8rem">
                    {errors.email}
                  </Text>
                </>
              )}
            </Label>
            {/* referrer_id */}
            <HiddenInput>

            <Label htmlFor="referrer_id">
              {touched.referrer_id && !errors.referrer_id && (
                <span>Referrer *</span>
              )}
                
              <Input
                type="name"
                name="referrer_id"
                id="referrer_id"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="none"
                placeholder={"referrerid"}
                isvalid={touched.referrer_id && !errors.referrer_id}
                error={touched.referrer_id && errors.referrer_id}
              />
            </Label>
            </HiddenInput>

            {/* referrer_id */}
            {/* <Label htmlFor="referrer_id">

            
            <HiddenInput
                type="referrer_id"
                name="referrer_id"
                id="referrer_id"
                autoComplete="none"
              />
</Label> */}
            {isError && (
              <Text variant="small" color="alertRed" mb="1.8rem">
                {log && log.first_name
                  ? log.first_name + " was invited"
                  : "Something went wrong"}
              </Text>
            )}
            <Text variant="small" mb="1.8rem">
                {log && log.first_name
                  ? log.first_name + " was invited! 👍"
                  : ""}
              </Text>

            <Box mt="2.4rem" textAlign="center">
              <Button
                variant="special"
                type="submit"
                disabled={
                  isSubmitting ||
                  !touched.first_name ||
                  !touched.email ||
                  (touched.name && errors.name) ||
                  (touched.email && errors.email)
                }
              >
                {isSubmitting ? `Submitting...` : cta}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Text>{invited?.first_name}</Text>
    </Box>
  )
}

ReferFriend.propTypes = {
  headerOne: PropTypes.string,
  headerTwo: PropTypes.string,
  cta: PropTypes.string,
  navigateTo: PropTypes.string,
}

const HiddenInput = styled.div`
  display: none;
`
